import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Activity } from "../activity/activity.view";
import { parseError } from "src/adapters/error";
import { getIsDepositWarningDismissed, setIsDepositWarningDismissed } from "src/adapters/storage";

import { ReactComponent as SidebarIcon } from "src/assets/icons/sidebar_icon.svg";
import { ReactComponent as SideBarLink01 } from "src/assets/icons/sidebar_link01.svg";
import { ReactComponent as SideBarLink02 } from "src/assets/icons/sidebar_link02.svg";
import { ReactComponent as SideBarLink03 } from "src/assets/icons/sidebar_link03.svg";
import { useEnvContext } from "src/contexts/env.context";
import { useErrorContext } from "src/contexts/error.context";
import { useFormContext } from "src/contexts/form.context";
import { useHomeTabsContext } from "src/contexts/homeTab.context";
import { useProvidersContext } from "src/contexts/providers.context";
import { useUIContext } from "src/contexts/ui.context";
import { FormData, Message, ModalState } from "src/domain";
import { useCallIfMounted } from "src/hooks/use-call-if-mounted";
import { routes } from "src/routes";
import { isMetaMaskUserRejectedRequestError } from "src/utils/types";
// import { getPartiallyHiddenEthereumAddress } from "src/utils/addresses";
import { BridgeForm } from "src/views/home/components/bridge-form/bridge-form.view";
import { BridgeTo } from "src/views/home/components/bridge-to/bridge-to.view";
import { Header } from "src/views/home/components/header/header.view";
import { useHomeStyles } from "src/views/home/home.styles";

export const Home = (): JSX.Element => {
  const classes = useHomeStyles();
  const navigate = useNavigate();
  const env = useEnvContext();
  const { formData, setFormData } = useFormContext();
  const { addNetwork, connectedProvider } = useProvidersContext();
  const [depositWarningModal, setDepositWarningModal] = useState<ModalState<FormData>>({
    status: "closed",
  });

  const { currentTabs, onChangeTabs } = useHomeTabsContext();

  const [selected, setSelected] = useState<"deposit" | "withDraw">("deposit");

  const callIfMounted = useCallIfMounted();
  const { openSnackbar } = useUIContext();
  const { notifyError } = useErrorContext();

  const onSubmitForm = (formData: FormData, hideDepositWarning?: boolean) => {
    if (hideDepositWarning) {
      setIsDepositWarningDismissed(hideDepositWarning);
    }
    setFormData(formData);
    navigate(routes.bridgeConfirmation.path);
  };

  const onCheckShowDepositWarningAndSubmitForm = (formData: FormData) => {
    onSubmitForm(formData);
  };

  const onResetForm = () => {
    setFormData(undefined);
  };

  if (!env) {
    return <></>;
  }
  const polygonZkEVMChain = env.chains[1];

  const successMsg: Message = {
    text: `${polygonZkEVMChain.name} network successfully added`,
    type: "success-msg",
  };
  const onAddNetwork = (): void => {
    addNetwork(polygonZkEVMChain)
      .then(() => {
        callIfMounted(() => {
          openSnackbar(successMsg);
        });
      })
      .catch((error) => {
        callIfMounted(() => {
          void parseError(error).then((parsed) => {
            if (parsed === "wrong-network") {
              openSnackbar(successMsg);
            } else if (isMetaMaskUserRejectedRequestError(error) === false) {
              notifyError(error);
            }
          });
        });
      });
  };

  const depositButtonClass = `${classes.withDrawButton} ${
    selected === "deposit" ? classes.withDrawButton + "-selected" : ""
  }`;
  const withdrawButtonClass = `${classes.withDrawButton} ${
    selected === "withDraw" ? classes.withDrawButton + "-selected" : ""
  }`;

  const bridgeButtonClass = `${classes.sideBarButton} ${
    currentTabs === "bridge" ? classes.sideBarButton + "-selected" : ""
  }`;

  const activitiesButtonClass = `${classes.sideBarButton} ${
    currentTabs === "activities" ? classes.sideBarButton + "-selected" : ""
  }`;

  return (
    <div className={classes.contentWrapper}>
      <div
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <div
          style={{
            background: "rgba(255, 255, 255, 0.10)",
            borderRight: "1px solid rgba(255, 255, 255, 0.15)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 24,
            width: 200,
          }}
        >
          <div>
            <div className={classes.sideBarIconWrapper}>
              <SidebarIcon width="30" />
              <div
                style={{
                  color: "#fff",
                  fontSize: 24,
                  fontWeight: 600,
                  marginTop: 6,
                }}
              >
                Nanon
              </div>
            </div>

            <div className={classes.sideBarButtonWrapper}>
              <div
                className={bridgeButtonClass}
                onClick={() => {
                  onChangeTabs("bridge");
                }}
              >
                Bridge
              </div>
              <div
                className={activitiesButtonClass}
                onClick={() => {
                  onChangeTabs("activities");
                }}
              >
                Activities
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <div className={classes.sideBarBottomButtonWrapper} onClick={onAddNetwork}>
              <SideBarLink01 height="24" width="24" />
              <span className={classes.sideBarBottomSpan}>Add Nanon To Wallet</span>
            </div>
            <div className={classes.sideBarBottomButtonWrapper}>
              <SideBarLink02 height="24" width="24" />
              <a
                href="https://www.alchemy.com/faucets/ethereum-sepolia"
                rel="noreferrer"
                target="_blank"
              >
                <span className={classes.sideBarBottomSpan}>Gas Faucet</span>
              </a>
            </div>
            <div className={classes.sideBarBottomButtonWrapper}>
              <SideBarLink03 height="24" width="24" />
              <a href="https://testexplorer.nanon.network" rel="noreferrer" target="_blank">
                <span className={classes.sideBarBottomSpan}>Try Testnet</span>
              </a>
            </div>
          </div>
        </div>

        {currentTabs === "bridge" ? (
          <div style={{ flex: 1 }}>
            <Header />
            <div className={classes.buttonContainer}>
              <div className={depositButtonClass} onClick={() => setSelected("deposit")}>
                Deposit
              </div>
              <div className={withdrawButtonClass} onClick={() => setSelected("withDraw")}>
                WithDraw
              </div>
            </div>
            {selected === "deposit" && (
              <BridgeForm
                account={
                  connectedProvider.status === "successful"
                    ? connectedProvider?.data?.account
                    : undefined
                }
                formData={formData}
                onResetForm={onResetForm}
                onSubmit={onCheckShowDepositWarningAndSubmitForm}
              />
            )}

            {selected === "withDraw" && (
              <BridgeTo
                account={
                  connectedProvider.status === "successful"
                    ? connectedProvider?.data?.account
                    : undefined
                }
                formData={formData}
                onResetForm={onResetForm}
                onSubmit={onCheckShowDepositWarningAndSubmitForm}
              />
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Header />
            <Activity />
          </div>
        )}
      </div>
    </div>
  );
};
