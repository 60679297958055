import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface HomeTabContext {
  currentTabs: "bridge" | "activities";
  onChangeTabs: (tabs: "bridge" | "activities") => void;
}

const errorContextNotReadyErrorMsg = "The error context is not yet ready";

const homeTabContextDefaultValue: HomeTabContext = {
  currentTabs: "bridge",
  onChangeTabs: () => {
    throw new Error(errorContextNotReadyErrorMsg);
  },
};

const homeTabContext = createContext<HomeTabContext>(homeTabContextDefaultValue);

const HomeTabProvider: FC<PropsWithChildren> = (props) => {
  const [currentTabs, setCurrentTabs] = useState<"bridge" | "activities">("bridge");

  const onChangeTabs = useCallback((tabs: "bridge" | "activities") => {
    setCurrentTabs(tabs);
  }, []);

  const value = useMemo(() => ({ currentTabs, onChangeTabs }), [currentTabs, onChangeTabs]);

  return <homeTabContext.Provider value={value} {...props} />;
};

const useHomeTabsContext = (): HomeTabContext => {
  return useContext(homeTabContext);
};

export { HomeTabProvider, useHomeTabsContext };
