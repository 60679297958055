import { createUseStyles } from "react-jss";

import { Theme } from "src/styles/theme";

export const useLayoutStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: [0, "auto"],
    width: "100%",
  },
  layout: {
    background: "radial-gradient(60.47% 60.47% at 59.06% 42.55%, #211156 0%, #090316 100%)",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
  },
  linkContainer: {
    marginTop: theme.spacing(2),
  },
}));
