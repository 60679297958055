import { createUseStyles } from "react-jss";

import { Theme } from "src/styles/theme";

export const useHomeStyles = createUseStyles((theme: Theme) => ({
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(3),
    justifyContent: "center",
    marginBottom: theme.spacing(5),
    marginTop: 84,
  },
  contentWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 0,
  },

  sideBarBottomButtonWrapper: {
    "&:hover": {
      color: "#FB3BC6",
    },
    alignItems: "center",
    color: "rgba(255,255,255,0.8)",
    cursor: "pointer",
    display: "flex",
    gap: 8,
  },

  sideBarBottomSpan: {
    color: "inherit",
    fontSize: 12,
    fontWeight: 400,
    opacity: 0.85,
    textAlign: "center",
  },

  sideBarButton: {
    "&-selected": {
      background: "#4A31FF",
    },
    "&:hover": {
      border: "1px solid #4A31FF",
      opacity: 0.85,
    },
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.10)",
    borderRadius: theme.spacing(1),
    color: "rgba(255,255,255,0.8)",
    cursor: "pointer",
    display: "flex",
    height: 40,
    justifyContent: "center",
    width: "100%",
  },

  sideBarButtonWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    marginTop: 32,
  },

  sideBarIconWrapper: {
    alignItems: "center",
    display: "flex",
    gap: 16,
    justifyContent: "center",
  },

  withDrawButton: {
    "&-selected": {
      background: "#4A31FF",
    },
    "&:hover": {
      border: "1px solid #4A31FF",
      opacity: 0.85,
    },
    alignItems: "center",
    background: "transparent",
    borderRadius: theme.spacing(1),
    color: "rgba(255,255,255,0.8)",
    cursor: "pointer",
    display: "flex",
    height: 40,
    justifyContent: "center",
    width: 180,
  },
}));
