import { FC } from "react";

import { useEnvContext } from "src/contexts/env.context";
import { useHeaderStyles } from "src/views/home/components/header/header.styles";
import { NetworkSelector } from "src/views/shared/network-selector/network-selector.view";

export const Header: FC = () => {
  const classes = useHeaderStyles();
  const env = useEnvContext();

  if (!env) {
    return null;
  }

  return (
    <header className={classes.header}>
      <div className={`${classes.block} ${classes.rightBlock}`}>
        <NetworkSelector />
      </div>
    </header>
  );
};
